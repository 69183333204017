
import IconRightArrow from '@/components/svg/IconRightArrow.vue'
import IconStockroom from '@/components/svg/IconStockroom.vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    IconStockroom,
    IconRightArrow,
  },
  name: 'IconOutstock',
})
export default class IconOutstock extends Vue {}

<template>
  <SvgBase iconName="Link" width="50" height="24" fill="none">
    <path d="M9.16003 11.96H41.47" :stroke="color" :stroke-width="4"/>
    <path d="M11.9599 16.6003C9.39995 16.6003 7.31995 14.5203 7.31995 11.9603C7.31995 9.4003 9.39995 7.32031 11.9599 7.32031C14.5199 7.32031 16.5999 9.4003 16.5999 11.9603C16.5999 14.5203 14.5199 16.6003 11.9599 16.6003Z" :fill="color"/>
    <path d="M11.96 19.92C7.56999 19.92 4 16.35 4 11.96C4 7.56999 7.56999 4 11.96 4C14.25 4 16.29 4.98 17.75 6.53H22.6C20.62 2.66 16.6 0 11.96 0C5.36999 0 0 5.35999 0 11.96C0 18.55 5.35999 23.92 11.96 23.92C16.6 23.92 20.62 21.26 22.6 17.39H17.75C16.29 18.93 14.25 19.92 11.96 19.92Z" :fill="color"/>
    <path d="M33.1 11.9603C33.1 9.4003 35.18 7.32031 37.74 7.32031C40.3 7.32031 42.38 9.4003 42.38 11.9603C42.38 14.5203 40.3 16.6003 37.74 16.6003C35.18 16.6003 33.1 14.5203 33.1 11.9603Z" :fill="color"/>
    <path d="M37.74 19.92C42.13 19.92 45.7 16.35 45.7 11.96C45.7 7.56999 42.13 4 37.74 4C35.45 4 33.41 4.98 31.95 6.53H27.1C29.08 2.66 33.1 0 37.74 0C44.33 0 49.7 5.35999 49.7 11.96C49.7 18.55 44.34 23.92 37.74 23.92C33.1 23.92 29.08 21.26 27.1 17.39H31.95C33.41 18.93 35.46 19.92 37.74 19.92Z" :fill="color"/>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconLink extends Vue {}
</script>

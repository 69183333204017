<template>
  <div v-loading.fullscreen="loading" class="parent-dialog">
    <el-dialog @open="onDialogOpen" :lock-scroll="true" v-model="isVisible" width="100%" top="0" :title="dialogLabel"
      custom-class="parent-dialog bg-opacity-80" :close-on-click-modal="false" @closed="closeDialog">
      <div @click="goToDetail(item.id)" class="barcode-children" v-for="item in parent" :key="item.id">
        <BarcodeLabel :marginBottom="false" :label="item.label || item?.id || ''" :subLabel="item.id"
          :type="item.barcodeType" />
        <div class="text-center">
          <span class="text-xs">{{ $t('click_to_confirm_detail') }}</span>
        </div>
      </div>
      <div class="help-block-container mt-10">
        <el-button type="primary" @click="unpair()">
          <div class="action-button">
            <IconUnpairing />
            {{ $t('unpair_from_children') }}
          </div>
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import { IBarcode, IDisplayBarcodes } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, mixins } from 'vue-class-component'
import { Emit, PropSync } from 'vue-property-decorator'
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import BarcodeKeyValueInfoMixin from './mixins/BarcodeKeyValueInfoMixin.vue'
import IconUnpairing from '@/components/svg/IconUnpairing.vue'
import errorHandler from '@/utils/errorHandler'
import { getBarcode, unpairBarcode } from '@/utils/api'
import { addLabelToBarcode, openMessage } from '@/utils/helpers'
import { FETCH_BARCODE } from '@/store/actions'

// Mixins
@Options({
  components: { BarcodeLabel, IconUnpairing },
  emits: ['update:modelValue', 'update:parentDialogVisibility'],
  name: 'ParentDialog',
})
export default class ImageViewDialog extends mixins(BarcodeKeyValueInfoMixin) {
  @PropSync('parentDialogVisibility') isVisible!: boolean
  loading = false
  parent: IDisplayBarcodes[] = []

  @Emit('update:parentDialogVisibility')
  closeDialog() {
    return false
  }

  goToDetail(barcodeId: string) {
    this.closeDialog()
    this.$router.push({
      name: 'detail',
      params: {
        project: this.projectParam,
        barcodeId: barcodeId,
      },
    })
  }

  get projectParam() {
    return this.$store.getters.projectParam
  }

  async onDialogOpen() {
    this.loading = true
    try {
      if (this.parent.length < 1 || this.parent[0].id !== this.barcode.parentId) {
        // Force the list to empty. This should be removed when multiple parent list support
        this.parent.length = 0

        const parent = await getBarcode(this.barcode?.parentId || '')
        this.parent.push(parent)
        addLabelToBarcode(this.parent, this.project)
      }
    } catch (error) {
      errorHandler(error as Error)
    } finally {
      this.loading = false
    }
  }

  get queryPath() {
    return this.$route.query?.path ?? ''
  }

  async unpair() {
    await this.$confirm(this.$t('confirm_unpair'), '', {
      center: true,
      confirmButtonText: this.$t('ok'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(async () => {
        try {
          this.loading = true
          await unpairBarcode({
            parentBarcodeId: this.barcode?.parentId || '',
            isUnpairAll: false,
            unpairChildrenIds: [this.barcode?.id],
          })

          await this.$store.dispatch(FETCH_BARCODE, {
            id: this.$route.params.barcodeId,
            path: this.queryPath,
          })

          openMessage(this.$t('barcode unpaired'), 'success')
        } catch (error) {
          errorHandler(error as Error)
        } finally {
          this.loading = false
          this.closeDialog()
        }
      })
      .catch(() => true)
  }

  get barcode(): IBarcode {
    return this.$store.state.barcode?.barcode
  }

  get dialogLabel() {
    return this.$t('View Parent')
  }
}
</script>
<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';

.parent-dialog {
  &.el-dialog {
    .el-dialog__body {
      padding-top: 0;
    }
  }
}

.parent-dialog:deep() {
  &.el-dialog {
    background-color: #f2f2f2;
    border-radius: 10px;
    max-height: 90%;
    overflow: hidden;
    flex-direction: column;
    display: flex;
    margin: 0;

    &__header {
      background-color: #626262;

      font-weight: bold;
      border-bottom: 1px solid #bdbdbd;
      padding: 25px;

      span {
        color: white;
        font-weight: bold;
      }
    }

    &__body {
      padding-top: 0px;
      padding-right: 15px;
      padding-bottom: 30px;
      padding-left: 15px;
    }

    &__headerbtn {
      background: transparent;
      min-width: 0px;
      min-height: 0px;
      border-radius: 0px;
      box-shadow: none;

      i {
        color: white;
      }

    }
  }
}

.barcode-children {
  a {
    color: unset;
    text-decoration: none;
  }
}

.help-block-container {
  position: relative;
  box-sizing: border-box;
  padding: 0.5rem;

  button {
    width: 100%;
  }

  &__child {
    bottom: 0rem;
    right: 0;
    position: absolute;
  }
}

.w100 {
  flex: 1 0 100%;
}

// Override css class
.barcode-label-container {
  margin-bottom: 0px;
}
</style>

<template>
  <SvgBase iconName="recycle" width="36" height="36">
    <path
      d="M9.41541 17.1021L17.011 14.3627L13.9253 12.5365L17.9605 5.14858L22.7077 13.8647L25.477 12.2044L19.3846 0.998071C19.0682 0.50001 18.5934 0.167969 18.0396 0.167969C17.4857 0.167969 16.9319 0.50001 16.6154 0.998071L11.1561 10.8763L7.91211 8.88404L9.41541 17.1021Z"
      :fill="color"
    />
    <path
      d="M12.5802 29.9686H4.35165L9.0989 21.2525L6.32967 19.5923L0.237363 30.7987C-0.0791208 31.2967 -0.0791208 31.9608 0.237363 32.4589C0.553846 32.9569 1.02857 33.289 1.58242 33.289H12.5802V37.0244L18.5934 31.5457L12.5802 26.0671V29.9686Z"
      :fill="color"
    />
    <path
      d="M35.7626 30.799L30.2242 20.7548L33.389 18.8455L25.7934 16.1062L24.2901 24.3242L27.4549 22.415L31.5692 29.9689H22.0747V33.2893H34.4176C34.9714 33.2893 35.5252 32.9573 35.7626 32.4592C36.0791 31.9611 36.0791 31.2971 35.7626 30.799Z"
      :fill="color"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconRecycle extends Vue {}
</script>

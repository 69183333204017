
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import IconEdit from '@/components/svg/IconEdit.vue'

@Options({
  components: {
    IconEdit,
  },
  name: 'TrackingPointNameBox',
})
export default class TrackingPointNameBox extends Vue {
  @Prop({ type: Boolean }) readonly editable?: boolean
  @Prop({ type: String }) readonly title!: string
  @Prop({ type: String }) readonly view?: string
  @Prop({ type: Object }) readonly data!: {
    current: string
    start: string
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  getDisplayValue(value: string | number) {
    return typeof value !== 'number' ? value : value.toFixed(1)
  }

  onClickEdit() {
    this.$router.push({ name: 'edit', params: { view: this.view } })
  }
}

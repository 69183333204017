<template>
  <el-button
    type="primary"
    class="half-button leftside"
    :disabled="isDisable"
    @click="onClickActionButton('pair-with-keyence-handyterminal')"
  >
    <div class="action-button">
      <IconPairing />
      {{ $t('pair_with_keyence_handyterminal') }}
    </div>
  </el-button>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import IconPairing from '../svg/IconPairing.vue'
import { IBarcode } from 'smartbarcode-web-core/src/utils/types/index'

@Options({
  components: {
    IconPairing,
  },
})
export default class PairScanButton extends Vue {
  @Prop({ type: Boolean }) readonly isDisable?: boolean = false
  @Prop({ type: Object }) readonly barcode!: IBarcode

  async onClickActionButton(view: string, id?: string) {
    this.$router.push({
      name: view,
      params: {
        project: this.projectParam,
        barcodeId: id || this.barcode?.id,
      },
    })
  }

  get projectParam() {
    return this.$store.getters.projectParam
  }
}
</script>

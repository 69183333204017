<template>
  <SvgBase iconName="IconMaskedLock" width="10" height="13">
    <path
      d="M10 6.5C10 5.78305 9.43938 5.2 8.75 5.2H8.125V3.25C8.125 1.45795 6.72312 0 5 0C3.27688 0 1.875 1.45795 1.875 3.25V5.2H1.25C0.560625 5.2 0 5.78305 0 6.5V11.7C0 12.4169 0.560625 13 1.25 13H8.75C9.43938 13 10 12.4169 10 11.7V6.5ZM3.125 3.25C3.125 2.1749 3.96625 1.3 5 1.3C6.03375 1.3 6.875 2.1749 6.875 3.25V5.2H3.125V3.25Z"
      fill="black"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconMaskedLock extends Vue {}
</script>

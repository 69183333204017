<template>
  <div class="button-container">
    <div v-show="!isHideTracing" class="help-block-container w100">
      <el-button type="primary" @click="onClickActionButton('trace')">
        <div class="action-button">
          <IconTrace />
          {{ $t('Trace') }}
        </div>
      </el-button>
    </div>

    <!-- ======== CLIENT BUTTONS: PARENT, CHILDREN ======== -->
    <template v-if="(!isAuth || isClientUser) && !queryPath">
      <!-- Check parent button -->
      <div v-if="!isHideParent" class="help-block-container w-1/2">
        <el-button
          type="primary"
          class="half-button leftside"
          :disabled="!allowToCheckParent"
          @click="onParentButtonClicked"
        >
          <div class="action-button">
            <IconCheckParent />
            {{ $t('View Parent') }}
          </div>
        </el-button>
        <div v-if="!allowToCheckParent" class="help-block-container__child">
          <el-popover placement="top-start" :width="200" trigger="hover" :content="$t(helpMessage.checkParent)">
            <template #reference>
              <IconHelp />
            </template>
          </el-popover>
        </div>
      </div>

      <!-- Check children button -->
      <div v-show="!isHideChildren" class="help-block-container w-1/2">
        <el-button
          type="primary"
          class="half-button"
          :disabled="!allowToCheckChildren"
          @click="onClickActionButton('children', barcode.id)"
        >
          <div class="action-button">
            <IconCheckChildren />
            {{ $t('View Children') }}
          </div>
        </el-button>
        <div v-if="!allowToCheckChildren" class="help-block-container__child">
          <el-popover placement="top-start" :width="200" trigger="hover" :content="$t(helpMessage.viewChildren)">
            <template #reference>
              <IconHelp />
            </template>
          </el-popover>
        </div>
      </div>
    </template>

    <!-- ======== CARRIERS BUTTONS: CREATE TRACKING DATA, PARENT, CHILDREN, PAIR, UNPAIR, HISTORY, RECYCLE ========== -->
    <template v-if="isAuth && !isClientUser && !queryPath">
      <!-- Create tracking point button -->
      <div class="help-block-container w100" @click="onClickTrackDisabledButton()">
        <el-button
          type="primary"
          class="btn-original"
          :disabled="!allowToCreateTrackpoint"
          @click="onClickTrackButton()"
        >
          <div class="action-button">
            <IconLocation />
            {{ $t('Create Tracking Data') }}
          </div>
        </el-button>
        <div v-if="!allowToCreateTrackpoint" class="help-block-container__child">
          <el-popover placement="top-start" :width="200" trigger="hover" :content="$t(helpMessage.createTrackingPoint)">
            <template #reference>
              <IconHelp />
            </template>
          </el-popover>
        </div>
      </div>

      <!-- Barcode link button -->
      <div class="help-block-container w100" v-if="isShowLinkButton">
        <KeyenceHTLinkScanButton
          v-if="isKeyenceEnterpriseBrowser"
          :isDisable="!allowBarcodeLinkTrackPoint"
          :barcode="barcode"
        />
        <el-button type="primary" :disabled="!allowBarcodeLinkTrackPoint" v-else @click="onClickBarcodeLink()">
          <div class="action-button">
            <IconBarcodeLink />
            {{ $t('barcode_link') }}
          </div>
        </el-button>
        <div v-if="!allowBarcodeLinkTrackPoint" class="help-block-container__child">
          <el-popover placement="top-start" :width="200" trigger="hover" :content="$t(helpMessage.linkBarcode)">
            <template #reference>
              <IconHelp />
            </template>
          </el-popover>
        </div>
      </div>
      <div class="help-block-container w100">
        <el-button
          v-if="isStockOperationAvailable"
          type="primary"
          :class="isInstockAvailable ? 'instock-btn' : `outstock${!isStockOperationAvailable ? '-disabled-' : '-'}btn`"
          @click="onClickStockOperations()"
        >
          <div class="action-button">
            <IconInstock v-if="isInstockAvailable" />
            <IconOutstock v-else />
            {{ $t(`location.${stockOperation}`) }}
          </div>
        </el-button>
      </div>

      <!-- Check parent button -->
      <div v-show="!isHideParent" class="help-block-container w-1/2">
        <el-button
          type="primary"
          class="half-button leftside"
          :disabled="!allowToCheckParent"
          @click="onParentButtonClicked"
        >
          <div class="action-button">
            <IconCheckParent />
            {{ $t('View Parent') }}
          </div>
        </el-button>
        <div v-if="!allowToCheckParent" class="help-block-container__child">
          <el-popover placement="top-start" :width="200" trigger="hover" :content="$t(helpMessage.checkParent)">
            <template #reference>
              <IconHelp />
            </template>
          </el-popover>
        </div>
      </div>

      <!-- Check children button -->
      <div v-show="!isHideChildren" class="help-block-container w-1/2">
        <el-button
          type="primary"
          class="half-button"
          :disabled="!allowToCheckChildren"
          @click="onClickActionButton('children', barcode.id)"
        >
          <div class="action-button">
            <IconCheckChildren />
            {{ $t('View Children') }}
          </div>
        </el-button>
        <div v-if="!allowToCheckChildren" class="help-block-container__child">
          <el-popover placement="top-start" :width="200" trigger="hover" :content="$t(helpMessage.viewChildren)">
            <template #reference>
              <IconHelp />
            </template>
          </el-popover>
        </div>
      </div>

      <hr class="w100" v-if="isVisiblePair" />

      <!-- Pair button -->
      <template v-if="isVisiblePair">
        <div class="help-block-container w-1/2" v-if="!isClientUser">
          <KeyenceHTPairScanButton v-if="isKeyenceEnterpriseBrowser" :isDisable="!allowToPair" :barcode="barcode" />
          <el-button
            v-else
            type="primary"
            class="half-button leftside"
            :disabled="!allowToPair"
            @click="onClickActionButton('pair')"
          >
            <div class="action-button">
              <IconPairing />
              {{ $t('pair') }}
            </div>
          </el-button>
          <div v-if="!allowToPair" class="help-block-container__child">
            <el-popover placement="top-start" :width="200" trigger="hover" :content="$t(helpMessage.checkPair)">
              <template #reference>
                <IconHelp />
              </template>
            </el-popover>
          </div>
        </div>
        <div class="help-block-container w-1/2" v-if="!isClientUser">
          <el-button type="primary" class="half-button" :disabled="!allowToUnpair" @click="unpairingOptions">
            <div class="action-button">
              <IconUnpairing />
              {{ $t('unpair') }}
            </div>
          </el-button>
          <div v-if="!allowToUnpair" class="help-block-container__child">
            <el-popover placement="top-start" :width="200" trigger="hover" :content="$t(helpMessage.checkUnpair)">
              <template #reference>
                <IconHelp />
              </template>
            </el-popover>
          </div>
        </div>
      </template>

      <hr class="w100" v-if="!isHideRecycle" />

      <div class="help-block-container w-1/2" v-if="!isHideRecycle">
        <el-button
          type="primary"
          class="half-button leftside"
          :disabled="false"
          @click="onClickActionButton('history')"
        >
          <div class="action-button">
            <IconHistory />
            {{ $t('History') }}
          </div>
        </el-button>
        <div v-if="false" class="help-block-container__child">
          <el-popover placement="top-start" :width="200" trigger="hover" :content="$t(helpMessage.checkPair)">
            <template #reference>
              <IconHelp />
            </template>
          </el-popover>
        </div>
      </div>
      <div class="help-block-container w-1/2" v-if="!isHideRecycle">
        <el-button type="primary" class="half-button" :disabled="!isAllowRecycle" @click="comfirmSubmitDialog">
          <div class="action-button">
            <IconRecycle />
            {{ $t('Recycle') }}
          </div>
        </el-button>
        <div v-if="!isAllowRecycle" class="help-block-container__child">
          <el-popover placement="top-start" :width="200" trigger="hover" :content="$t(helpMessage.checkRecycle)">
            <template #reference>
              <IconHelp />
            </template>
          </el-popover>
        </div>
      </div>
    </template>
  </div>
  <el-dialog
    :lock-scroll="true"
    width="90%"
    top="0"
    :model-value="showDialogAlert"
    :close-on-click-modal="true"
    :show-close="false"
    @closed="onDialogClose()"
  >
    <div class="dialogMessage">
      {{ $t(errorDialogMessage) }}
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onClickRecycle" v-if="isRecycleDialog">
          {{ $t('OK') }}
        </el-button>
        <el-button type="primary" @click="showDialogAlert = false" v-else>
          {{ $t('OK') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
  <div class="dialog-area">
    <el-dialog
      center
      v-model="isShowEntryDialog"
      width="90%"
      top="0"
      :show-close="false"
      :destroy-on-close="false"
      :title="$t('confirm_activate_data')"
      @close="isShowNotAddDataDialog = false"
    >
      <div class="dialog-content-data">
        <div class="activation-data-content">
          {{ $t('not_have_enough_activation_data') }}
        </div>
        <br />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button round @click="isShowNotAddDataDialog = false" class="cancel-button">
            <span>
              {{ $t('cancel') }}
            </span>
          </el-button>
          <el-button round class="ok-btn" @click="navigateToEntryPage">
            {{ $t('OK') }}
          </el-button>
        </span>
      </template>
    </el-dialog>

    <StockOperationsDialog
      v-if="isStockOperationAvailable"
      v-model:isShow="isShowInstockOperationDialog"
      :operationType="stockOperation"
    />

    <el-dialog
      center
      v-model="isShowUnpairDialog"
      width="90%"
      top="0"
      :show-close="true"
      :destroy-on-close="false"
      :title="$t('unpair')"
      @close="isShowUnpairDialog = false"
    >
      <div class="dialog-content-data">
        <div class="activation-data-content">
          {{ $t('choose_unpairing_option') }}
        </div>
        <br />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button round @click="unpair('scanned')" class="full-w-button">
            {{ $t('unpairing_scanned_barcodes') }}
          </el-button>
          <el-button round type="primary" @click="unpair('all')" class="full-w-button">
            {{ $t('unpairing_all_barcodes') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
    <LinkingOptionsDialog v-model:isShow="isShowLinkingOptionsDialog" />
  </div>
</template>
<script lang="ts">
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import NotFound from '@/components/common/NotFound.vue'
import CompanyName from '@/components/CompanyName.vue'
import DetailAddressBox from '@/components/DetailAddressBox.vue'
import LinkingOptionsDialog from '@/components/dialog/LinkingOptionsDialog.vue'
import KeyenceHTLinkScanButton from '@/components/keyenceHT/LinkScanButton.vue'
import KeyenceHTPairScanButton from '@/components/keyenceHT/PairScanButton.vue'
import IconActivate from '@/components/svg/IconActivate.vue'
import IconBarcodeLink from '@/components/svg/IconBarcodeLink.vue'
import IconCheckChildren from '@/components/svg/IconCheckChildren.vue'
import IconCheckParent from '@/components/svg/IconCheckParent.vue'
import IconEdit from '@/components/svg/IconEdit.vue'
import IconHelp from '@/components/svg/IconHelp.vue'
import IconHistory from '@/components/svg/IconHistory.vue'
import IconInstock from '@/components/svg/IconInstock.vue'
import IconLink from '@/components/svg/IconLink.vue'
import IconLocation from '@/components/svg/IconLocation.vue'
import IconOutstock from '@/components/svg/IconOutstock.vue'
import IconPairing from '@/components/svg/IconPairing.vue'
import IconPrinter from '@/components/svg/IconPrinter.vue'
import IconRecycle from '@/components/svg/IconRecycle.vue'
import IconTrace from '@/components/svg/IconTrace.vue'
import IconUnlink from '@/components/svg/IconUnlink.vue'
import IconUnpairing from '@/components/svg/IconUnpairing.vue'
import TrackingPointNameBox from '@/components/TrackingPointNameBox.vue'
import {
  BARCODE_ACTION_COMPLETE,
  CLEAR_NOTIFICATION,
  FETCH_BARCODE,
  FETCH_LOCATIONS,
  OUTSTOCK_LOCATION,
} from '@/store/actions'
import { recycleBarcode, unpairBarcode } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { isHideVisibilityConfig, openMessage } from '@/utils/helpers'
import { isKeyenceEnterpriseBrowser } from '@/utils/keyenceHT/keyenceModules'
import StockOperationsDialog from '@/views/StockOperationsDialog.vue'
import { AxiosResponse } from 'axios'
import { cloneDeep } from 'lodash'
import { EDisplaySetting, EStockOperationType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcode,
  IBarcodeDefinitionType,
  IHelpMessage,
  IProject,
  ITrackpoint,
  TError,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'

@Options({
  components: {
    LinkingOptionsDialog,
    IconUnpairing,
    IconPairing,
    IconActivate,
    IconPrinter,
    IconTrace,
    IconLocation,
    IconBarcodeLink,
    IconCheckParent,
    IconCheckChildren,
    IconEdit,
    IconLink,
    IconUnlink,
    IconHelp,
    IconInstock,
    IconOutstock,
    CompanyName,
    DetailAddressBox,
    BarcodeLabel,
    NotFound,
    TrackingPointNameBox,
    IconRecycle,
    IconHistory,
    StockOperationsDialog,
    KeyenceHTLinkScanButton,
    KeyenceHTPairScanButton,
  },
  emits: ['update:loading', 'update:onParentButtonClicked'],
  name: 'DetailPageButtons',
})
export default class DetailPageButtons extends Vue {
  @Prop({ type: Object }) readonly barcodeTypeInfo!: IBarcodeDefinitionType
  @Prop({ type: Object }) readonly barcode!: IBarcode
  @Prop({ type: String }) readonly currentTrackPointKey!: string
  @Prop({ type: Number }) readonly childrenCount!: number

  @PropSync('loading', { type: Boolean }) _loading!: boolean

  isShowInstockOperationDialog = false
  isShowUnpairDialog = false
  showDialogAlert = false
  isRecycleDialog = false
  helpMessage: IHelpMessage = {}
  errorDialogMessage = ''
  isShowNotAddDataDialog = false
  isShowLinkingOptionsDialog = false

  get locations() {
    return this.$store.state.location.locations
  }

  get isShowLinkButton() {
    const linkableTPs = Object.entries(cloneDeep(this.barcodeTypeInfo?.linking ?? {}))
      .filter(([k, v]) => v.isAvailable)
      .map(([k, v]) => k)

    const indexEndNode = linkableTPs.findIndex((item: string) => item === this.$store.getters.projectEndTrackpointKey)
    if (indexEndNode > -1) linkableTPs.splice(indexEndNode, 1)
    return linkableTPs && linkableTPs.length > 0
  }

  get isShowEntryDialog() {
    return !this.allowToCreateTrackpoint && this.isShowNotAddDataDialog && this.isAuth && !this.barcode?.isDeactivated
  }

  get isHideParent(): boolean {
    const visibility = this.barcodeTypeInfo?.uiConfig?.parentBarcodeVisibility || EDisplaySetting.HIDDEN
    return isHideVisibilityConfig(visibility, this.isAuth)
  }

  get isHideChildren(): boolean {
    const visibility = this.barcodeTypeInfo?.uiConfig?.childrenBarcodeVisibility || EDisplaySetting.HIDDEN
    return isHideVisibilityConfig(visibility, this.isAuth)
  }

  get isHideTracing(): boolean {
    const visibility = this.barcodeTypeInfo?.uiConfig?.tracingVisibility || EDisplaySetting.HIDDEN
    return isHideVisibilityConfig(visibility, this.isAuth)
  }

  get isUIConfigAvailable() {
    return !isEmpty(this.barcodeTypeInfo?.uiConfig)
  }

  get isAuth(): boolean {
    return this.$store.getters.isAuth
  }

  get isClientUser(): boolean {
    return this.$store.getters.isClientUser
  }

  get queryPath() {
    return this.$route.query?.path ?? ''
  }

  get isVisiblePair(): boolean {
    return this.barcodeTypeInfo?.allowToPair
  }

  get projectParam() {
    return this.$store.getters.projectParam
  }

  get hasParent(): boolean {
    return this.barcode?.hasParent
  }

  get allowToPair(): boolean {
    if (this.isClientUser) {
      return false
    }

    if (this.barcode?.isDeactivated) {
      this.helpMessage.checkPair = 'barcode deactivated'
      return false
    }

    // check if current point allows pair
    if (this.barcodeTypeInfo?.pairing?.[this.currentTrackPointKey]?.isAvailable) {
      return true
    }

    this.helpMessage.checkPair = 'pair_prohibit'
    return false
  }

  get allowToUnpair(): boolean {
    if (this.isClientUser) {
      return false
    }

    if (this.barcode?.isDeactivated) {
      this.helpMessage.checkUnpair = 'barcode deactivated'
      return false
    }

    this.helpMessage.checkUnpair = 'unpair_prohibit'
    // check if current point allows unpair
    if (this.barcodeTypeInfo?.unpairing?.[this.currentTrackPointKey]?.isAvailable) {
      // disable unpair if there are no children
      if (this.childrenCount < 1) {
        this.helpMessage.checkUnpair = 'barcode not have children'
        return false
      }
      // allow unpair if there are children
      return this.childrenCount > 0
    }

    return false
  }

  onDialogClose() {
    this.showDialogAlert = false
  }

  onParentButtonClicked() {
    this.$emit('update:onParentButtonClicked')
  }

  get allowToCheckParent(): boolean {
    if (!this.barcode?.hasParent) {
      this.helpMessage.checkParent = 'barcode not have parent'
      return false
    }
    return true
  }

  get allowToCheckChildren(): boolean {
    if (this.barcode?.childrenCount === 0) {
      this.helpMessage.viewChildren = 'barcode not have children'
      return false
    }
    return true
  }

  get allowBarcodeLinkTrackPoint(): boolean {
    if (this.barcode?.isDeactivated) {
      this.helpMessage.linkBarcode = 'barcode deactivated'
      return false
    }

    this.helpMessage.linkBarcode = 'barcode_link_prohibit'
    return (
      Object.entries(this.barcodeTypeInfo?.linking ?? {})
        .filter(([k, v]) => v.isAvailable)
        .map(([k, v]) => k)
        .includes(this.currentTrackPointKey) || false
    )
  }

  get allowToCreateTrackpoint(): boolean {
    if (this.barcode?.isDeactivated) {
      this.helpMessage.createTrackingPoint = 'barcode deactivated'
      return false
    }
    this.helpMessage.createTrackingPoint = 'barcode_not_ready'
    return !!this.barcode?.isReadyToAddTrackData
  }

  get isCurrentRecycleableTrackpoint(): boolean {
    return (this.barcodeTypeInfo?.barcodeRecyclableTrackPoints ?? []).includes(this.currentTrackPointKey)
  }

  get isAllowRecycle(): boolean {
    if (!this.isCurrentRecycleableTrackpoint) {
      // Disable Recycle button
      this.helpMessage.checkRecycle = 'cant_recycle_at_this_trackpoint'
      return false
    }

    if (this.childrenCount > 0) {
      if (!this.project.isRecycleChildren || this.hasParent) {
        this.helpMessage.checkRecycle = 'errors.3025'
        return false
      }
    }
    return true
  }

  get project(): IProject {
    return this.$store.state.project.details
  }

  get isHideRecycle(): boolean {
    return this.isClientUser || !this.barcodeTypeInfo?.allowBarcodeRecycle || this.barcode?.id !== this.barcode?.rootId
  }

  onClickActionButton(view: string, id?: string) {
    this.$router.push({
      name: view,
      params: {
        project: this.projectParam,
        barcodeId: id || this.barcode?.id,
      },
    })
  }

  onClickTrackDisabledButton() {
    if (this.allowToCreateTrackpoint) return

    if (!this.barcode?.isReadyToAddTrackData) {
      this.isShowNotAddDataDialog = true
    }
  }

  onClickTrackButton() {
    const isCurrentLinkMandatory = !!this.barcodeTypeInfo?.linking?.[this.currentTrackPointKey]?.isMandatory
    const hasLinkedBarcodes = (): boolean => {
      const hasActivationDataLinkedBarcodeAtCurrentTP = !!Object.entries(
        this.project.trackPoints as Record<string, ITrackpoint>
      ).find(([k, v]) => v.isStart && k === this.currentTrackPointKey)
      const hasTPLinkedBarcodeAtCurrentTP = !isEmpty(
        this.barcode.trackingData[Number(this.currentTrackPointKey)].linkedBarcodes
      )
      return hasActivationDataLinkedBarcodeAtCurrentTP || hasTPLinkedBarcodeAtCurrentTP
    }

    const isCurrentPairMandatory = !!this.barcodeTypeInfo?.pairing?.[this.currentTrackPointKey]?.isMandatory
    const isCurrentUnpairMandatory = !!this.barcodeTypeInfo?.unpairing?.[this.currentTrackPointKey]?.isMandatory

    if (isCurrentLinkMandatory && !hasLinkedBarcodes) {
      this.errorDialogMessage = 'link_mandatory'
      this.showDialogAlert = true
      return
    }

    // if pair is Mandatory and haven't pair yet
    if (isCurrentPairMandatory && this.childrenCount < 1) {
      this.errorDialogMessage = 'pair_mandatory'
      this.showDialogAlert = true
      return
    }

    // if unpair is Mandatory and haven't unpair yet
    if (isCurrentUnpairMandatory && this.childrenCount > 0) {
      this.errorDialogMessage = 'unpair_mandatory'
      this.showDialogAlert = true
      return
    }

    this.onClickActionButton('track')
  }

  onClickBarcodeLink() {
    this.isShowLinkingOptionsDialog = true
  }

  get isStockOperationAvailable() {
    const isValidInstock = EStockOperationType.INSTOCK === this.stockOperation && !isEmpty(this.locations)
    const isValidOutStock = EStockOperationType.OUTSTOCK === this.stockOperation && !!this.barcode.locationId

    if (!isEmpty(this.barcode?.trackingData)) {
      this.helpMessage.location = 'location.location_help_msg'
    }
    if (!isValidInstock || !isValidOutStock) {
      this.helpMessage.location = 'location.location_not_found_in_current_tp'
    }

    // available tracking data and locations is not empty
    return !isEmpty(this.barcode?.trackingData) && (isValidInstock || isValidOutStock)
  }

  get stockOperation(): EStockOperationType {
    return isEmpty(this.barcode.locationId) ? EStockOperationType.INSTOCK : EStockOperationType.OUTSTOCK
  }

  get isInstockAvailable() {
    return this.stockOperation === EStockOperationType.INSTOCK
  }

  async onClickStockOperations() {
    if (this.stockOperation === EStockOperationType.INSTOCK) {
      this.isShowInstockOperationDialog = true
    } else {
      await this.$confirm(this.$t('location.confirm_outstock_msg'), '', {
        center: true,
        confirmButtonText: this.$t('ok'),
        confirmButtonClass: 'danger',
        cancelButtonText: this.$t('cancel'),
      })
        .then(async () => {
          this._loading = true
          await this.$store.dispatch(OUTSTOCK_LOCATION, { barcodeIds: [this.barcode.id] })
          openMessage(this.$t(`location.${EStockOperationType.OUTSTOCK.toLowerCase()}_success`), 'success')

          // reload barcode detail data & locations
          await this.$store.dispatch(FETCH_BARCODE, { id: this.barcode.id })
          await this.$store.dispatch(FETCH_LOCATIONS, {
            projectId: this.project.id,
            barcodeId: this.barcode.id,
            trackPointKey: this.barcode.currentTrackPointKey,
            isNew: true,
          })
        })
        .catch((e: TError) => {
          if (e !== 'cancel') errorHandler(e)
        })
        .finally(() => {
          this._loading = false
        })
    }
  }

  async unpairingOptions() {
    this.isShowUnpairDialog = true
  }

  async unpair(type: string) {
    switch (type) {
      case 'scanned':
        this.$router.push({
          name: 'barcode-unpair-scan',
          params: {
            project: this.projectParam,
            barcodeId: this.barcode?.id,
          },
        })
        break
      case 'all':
        try {
          await unpairBarcode({
            parentBarcodeId: this.barcode.id,
            isUnpairAll: true,
            unpairChildrenIds: [],
          })
          await this.$store.dispatch(FETCH_BARCODE, {
            id: this.$route.params.barcodeId,
            trackingNumber: this.trackingNumberFromPath,
            externalId: this.externalIdFromPath,
            path: this.queryPath,
          })
          this.$store.dispatch(BARCODE_ACTION_COMPLETE, 'unpaired')
        } catch (error) {
          errorHandler(error as TError)
        }
        break
      default:
        break
    }
  }

  comfirmSubmitDialog() {
    if (!this.showDialogAlert) {
      this.isRecycleDialog = true
      this.errorDialogMessage = 'confirm_recycle_barcode'
      this.showDialogAlert = true
    }
  }

  async onClickRecycle() {
    try {
      this.showDialogAlert = false
      this.isRecycleDialog = false
      this._loading = true

      const res = (await recycleBarcode(this.barcode?.id)) as AxiosResponse
      this.$store.commit(CLEAR_NOTIFICATION)
      if (res?.status === 200) {
        await this.$store.dispatch(FETCH_BARCODE, {
          id: this.$route.params.barcodeId,
          trackingNumber: this.trackingNumberFromPath,
          externalId: this.externalIdFromPath,
          path: this.queryPath,
        })
        this.$store.dispatch(BARCODE_ACTION_COMPLETE, 'recycle_complete')
      }
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this._loading = false
    }
  }

  navigateToEntryPage() {
    this.$router.push({ name: 'edit' })
  }

  get isKeyenceEnterpriseBrowser() {
    return isKeyenceEnterpriseBrowser()
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.button-container {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.help-block-container {
  position: relative;
  box-sizing: border-box;
  padding: 0.5rem;

  button {
    width: 100%;
  }

  &__child {
    bottom: 0rem;
    right: 0;
    position: absolute;
  }
}

.w100 {
  flex: 1 0 100%;
}

.dialog-area :deep() {
  .el-dialog__header {
    padding: 20px;
    text-align: left;
    background-color: #525252;

    .el-dialog__title {
      color: #ffffff;
    }
  }

  .el-dialog__body {
    word-break: normal;
  }

  .el-dialog__headerbtn {
    background-color: #ffffff;
    border-radius: 50%;
    min-width: 28px;
    min-height: 28px;
    width: 28px !important;
    height: 28px !important;
  }

  .barcode-label-container {
    display: flex;
    padding: 5px;
    margin: 0px;

    .action-menu {
      width: auto;
    }
  }
}

.activation-data-content {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  word-break: keep-all;
  text-align: center;
  color: #000000;
}

.dialog-content-data {
  font-family: 'Noto Sans', sans-serif;
  width: 100%;

  &__id {
    color: #979797;
  }

  &__key-value-item {
    padding: 4px;
    display: flex;
    color: black;

    div:first-child {
      text-align: right;
      margin-right: 5px;
      flex-basis: 50%;
      min-width: 50%;
    }

    div:last-child {
      text-align: left;
    }
  }
}

.cancel-button {
  min-width: 120px;
  background-color: #c4c4c4;
  color: #ffffff;

  &.is-round {
    height: 64px;
    border-radius: 40px !important;
    box-sizing: border-box !important;
  }
}

.instock-btn {
  background-color: #219653 !important;
  border-color: #219653 !important;
  color: $pure-white;

  &.is-disabled {
    background-color: #7fb897;
    border-color: #7fb897;
  }
}
.outstock-btn {
  background-color: #9b80d5 !important;
  border-color: #9b80d5 !important;
  color: $pure-white;
}

.outstock-disabled-btn {
  background-color: #beace4 !important;
  border-color: #beace4 !important;
  color: $pure-white;
}

.ok-btn {
  width: 64px;
  height: 64px;
  background: #525252;
  color: #ffffff;

  &.is-round {
    height: 64px;
    border-radius: 40px !important;
    box-sizing: border-box !important;
  }
}
</style>

<template>
  <SvgBase iconName="Image" width="24" height="12" fill="none">
    <path
      d="M1.89014 8.74L8.07014 2.53C8.64014 1.87 9.55014 1.98 9.43014 2.69L8.23014 9.36C8.10014 10.06 9.00014 10.18 9.57014 9.53L15.3101 3.02C15.9001 2.36 16.8101 2.5 16.6501 3.23L15.2601 9.3C15.0901 10.03 16.0201 10.17 16.6001 9.5L25.4401 2"
      :stroke="color"
      stroke-width="2.8"
      stroke-miterlimit="10"
    />
    <path d="M1 17.75H25.22" :stroke="color" stroke-width="2.8" stroke-miterlimit="10" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconSignature extends Vue {}
</script>

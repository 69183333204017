<template>
  <SvgBase iconName="barcode_link" width="31" height="31">
    <path
      d="M21.3333 0V3.04762H18.2857V0H21.3333ZM15.2381 0H12.1905V6.09524H15.2381V0ZM12.1905 18.2857H9.14286V21.3333H12.1905V18.2857ZM21.3333 3.04762V6.09524H18.2857V9.14286H24.381V3.04762H21.3333ZM9.14286 0H6.09524V6.09524H9.14286V0ZM27.4286 0H24.381V3.04762H27.4286V0ZM17.981 30.4762H0V0H3.04762V6.09524H6.09524V15.2381H3.04762V18.2857H6.09524V21.3333H9.14286V24.381H12.1905V27.4286H15.2381V24.381H12.1905V21.3333H15.2381V15.2381H9.14286V9.14286H15.2381V12.1905H18.2857V15.2381H21.3333V12.1905H24.381V15.2381H30.4762V17.981C29.1048 17.219 27.581 16.7619 25.9048 16.7619C24.0762 16.7619 22.2476 17.3714 20.8762 18.2857H18.2857V20.8762C17.3714 22.2476 16.7619 24.0762 16.7619 25.9048C16.7619 27.581 17.219 29.1048 17.981 30.4762ZM6.09524 24.381H3.04762V27.4286H6.09524V24.381ZM30.4762 9.14286H24.381V12.1905H30.4762V9.14286ZM30.4762 6.09524V3.04762H27.4286V6.09524H30.4762Z"
      :fill="color"
    />
    <path
      d="M25.7144 21L30.0001 25.2857L25.7144 29.5714"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 25.2856L30 25.2856"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconBarcodeLink extends Vue {}
</script>

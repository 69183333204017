
import { ELinkType } from '@/utils/enums'
import { Options, Vue } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'

@Options({
  components: {},
  name: 'LinkingOptionsDialog',
})
export default class LinkingOptionsDialog extends Vue {
  @PropSync('isShow', { type: Boolean }) _isShow!: boolean

  ELinkType = ELinkType

  link(linkType: ELinkType) {
    this.$router.push({
      name: `${linkType}-scan`,
      params: {
        project: this.$store.getters.projectParam,
        barcodeId: this.$store.state.barcode.barcode?.id,
      },
    })
  }
}

<template>
  <SvgBase iconName="recycle" width="37" height="37">
    <path
      d="M33.0178 5.40079C29.8089 1.92232 25.5022 0 20.9422 0C16.3822 0 12.0756 1.92232 8.86667 5.40079C5.65778 8.87927 3.88444 13.5478 3.88444 18.3993H0L5.57333 25.3563L11.1467 18.3993H7.17778C7.17778 14.4631 8.61333 10.71 11.2311 7.96388C13.7644 5.21772 17.2267 3.66155 20.9422 3.66155C24.6578 3.66155 28.0356 5.21772 30.6533 8.05542C33.2711 10.8931 34.7067 14.6462 34.7067 18.5824C34.7067 22.5186 33.2711 26.2717 30.6533 29.1094C28.0356 31.9471 24.5733 33.5032 20.9422 33.5032C17.3111 33.5032 13.8489 31.9471 11.2311 29.1094L8.86667 31.6724C12.0756 35.1509 16.3822 37.0732 20.9422 37.0732C25.5022 37.0732 29.8089 35.1509 33.0178 31.6724C36.2267 28.194 38 23.5255 38 18.5824C38 13.6393 36.2267 8.97081 33.0178 5.40079Z"
      :fill="color"
    />
    <path
      d="M19.2534 6.22412V20.7791H30.9068V17.1174H22.6312V6.22412H19.2534Z"
      :fill="color"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconRecycle extends Vue {}
</script>

<template>
  <SvgBase iconName="Activate" width="36" height="36" fill="none">
    <path
      d="M10.9964 3.49365C5.68846 5.95262 2 11.32 2 17.5522C2 26.1162 8.94447 33.0522 17.5 33.0522C26.064 33.0522 33 26.1077 33 17.5522C33 11.32 29.3115 5.9611 24.0036 3.49365"
      :stroke="color"
      stroke-width="4"
    />
    <path d="M17.5085 0V16.9415" :stroke="color" stroke-width="4"/>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconActivate extends Vue {}
</script>

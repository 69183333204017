<template>
  <div class="flex items-center" style="margin-bottom:8px; padding-left: 20px; height:32px">
    <IconStockroom style="margin-bottom: 0px; width:32px; height:32px;" />
    <IconRightArrow style="margin:0px 0px 0px 7px; width:19px; height:19px" />
  </div>
</template>

<script lang="ts">
import IconRightArrow from '@/components/svg/IconRightArrow.vue'
import IconStockroom from '@/components/svg/IconStockroom.vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    IconStockroom,
    IconRightArrow,
  },
  name: 'IconOutstock',
})
export default class IconOutstock extends Vue {}
</script>

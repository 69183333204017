<template>
  <div class="address-box" id="TrackingPointNameBox">
    <el-button v-if="isAuth && view && editable" type="primary" class="edit-button" @click="onClickEdit">
      <IconEdit />
    </el-button>
    <div class="label-box-top" :class="{ 'tracking-point': !view }">
      <div class="origin">{{ $t('start') }} : {{ getDisplayValue(data.start) }}</div>
    </div>
    <div class="label-box-bottom" :class="{ 'tracking-point': !view }">
      <div class="origin">
        <div class="currentTrackingLocateTitle">{{ $t('current') }}</div>
        <div class="currentTrackingLocateContent">
          {{ getDisplayValue(data.current) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import IconEdit from '@/components/svg/IconEdit.vue'

@Options({
  components: {
    IconEdit,
  },
  name: 'TrackingPointNameBox',
})
export default class TrackingPointNameBox extends Vue {
  @Prop({ type: Boolean }) readonly editable?: boolean
  @Prop({ type: String }) readonly title!: string
  @Prop({ type: String }) readonly view?: string
  @Prop({ type: Object }) readonly data!: {
    current: string
    start: string
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  getDisplayValue(value: string | number) {
    return typeof value !== 'number' ? value : value.toFixed(1)
  }

  onClickEdit() {
    this.$router.push({ name: 'edit', params: { view: this.view } })
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.address-box {
  padding: 14px 0;

  .label-box-top {
    margin-bottom: 1px;
    border-bottom: 1px solid #888;
    margin-right: 12px;
    letter-spacing: 0.1em;
    display: inline-block;
    background: #bdbdbd;
    border-radius: 8px 8px 0 0;
  }
  .label-box-bottom {
    margin-bottom: 2px;
    margin-right: 12px;
    letter-spacing: 0.1em;
    display: inline-block;
    background: #bdbdbd;
    border-radius: 0 0 8px 8px;
  }
}

.el-button.edit-button {
  padding: 6px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.tracking-point {
  background: $light-grey;
  padding: 4px 0;
  width: 100%;
  border: 0;
}
.origin {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.currentTrackingLocateTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  align-items: center;
  text-align: center;
  color: #000000;
}
.currentTrackingLocateContent {
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
</style>

<template>
  <SvgBase iconName="printer" width="32" height="32">
    <g clip-path="url(#clip0_11301_7878)">
      <path d="M12.9673 8.19922H7.77441V13.4208H12.9673V8.19922Z" fill="#F7F8F8" />
      <path d="M12.9673 18.6348H7.77441V23.8563H12.9673V18.6348Z" fill="#F7F8F8" />
      <path d="M18.1607 13.4141H12.9678V18.6356H18.1607V13.4141Z" fill="#F7F8F8" />
      <path d="M23.3453 8.19922H18.1523V13.4208H23.3453V8.19922Z" fill="#F7F8F8" />
      <path d="M23.3453 18.6348H18.1523V23.8563H23.3453V18.6348Z" fill="#F7F8F8" />
      <path
        d="M15.5328 31.604C6.96984 31.604 0 24.5958 0 15.9779C0 7.35997 6.96984 0.359375 15.5328 0.359375C24.1034 0.359375 31.0656 7.36767 31.0656 15.9779C31.0656 24.588 24.1034 31.604 15.5328 31.604ZM15.5328 3.43994C8.66252 3.43994 3.06367 9.06198 3.06367 15.9779C3.06367 22.8937 8.66252 28.5158 15.5328 28.5158C22.4107 28.5158 28.0019 22.8937 28.0019 15.9779C28.0019 9.06198 22.4107 3.43994 15.5328 3.43994Z"
        fill="#F7F8F8" />
      <path d="M25.5374 24.1831L23.3711 26.3613L29.8376 32.8635L32.0039 30.6852L25.5374 24.1831Z" fill="#F7F8F8" />
    </g>
    <defs>
      <clipPath id="clip0_11301_7878">
        <rect width="32" height="32.5" fill="white" transform="translate(0 0.359375)" />
      </clipPath>
    </defs>

  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconCheckParent extends Vue { }
</script>

<template>
  <SvgBase iconName="printer" width="34" height="40">
    <path
      d="M17 37C17 37 32 27 32 17C32 8.71667 25.2833 2 17 2C8.71667 2 2 8.71667 2 17C2 27 17 37 17 37Z"
      :stroke="color"
      stroke-width="4"
    />
    <path d="M10.4835 16.8003H23.1418" :stroke="color" stroke-width="4" />
    <path d="M16.8083 10.4668V23.1335" :stroke="color" stroke-width="4" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconLocation extends Vue {}
</script>

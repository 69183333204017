<template>
  <div class="address-box">
    <div class="flex">
      <div class="flex items-center justify-end" style="width: 25%">
        <el-popover v-if="eSignsArr?.length > 0 || isMaskedSignature" :disabled="!isMaskedSignature" placement="bottom"
          :width="180" trigger="click">
          <template #reference>
            <el-button type="primary" class="edit-button" style="position: relative" @click="onDisplaySignature">
              <IconSignature />
              <IconMaskedLock v-if="isMaskedSignature" class="absolute" style="bottom: 5px; right: 0" />
            </el-button>
          </template>
          <div style="background-color: white; color: #ff0000">{{ $t('Require View Permission') }}</div>
        </el-popover>
        <el-popover v-if="Object.keys(imageUrlsArr).length > 0 || isMaskedImage" :disabled="!isMaskedImage"
          placement="bottom" :width="180" trigger="click">
          <template #reference>
            <el-button type="primary" class="edit-button" style="position: relative" @click="onDisplayActivateImage">
              <IconImage />
              <IconMaskedLock v-if="isMaskedImage" class="absolute" style="bottom: 5px; right: 0" />
            </el-button>
          </template>
          <div style="background-color: white; color: #ff0000">{{ $t('Require View Permission') }}</div>
        </el-popover>
      </div>
      <div class="flex items-center justify-center" style="width: 50%">
        <div class="label-box w-full" style="margin: 0 10px 0 10px" :class="{ 'tracking-point': !view }">
          {{ title }}
        </div>
      </div>
      <div class="flex items-center justify-start mx-1">
        <el-button v-if="isAuth && view && editable" type="primary" class="edit-button" @click="onClickEdit">
          <IconEdit />
        </el-button>
      </div>
      <div class="flex items-center justify-start mx-1">
        <el-button v-if="isAuth && view && hasReferenceFields" type="primary" class="edit-button"
          @click="onClickRefreshReferenceField">
          <i class="el-icon-refresh"></i>
        </el-button>
      </div>
    </div>

    <div class="label-and-value" v-for="(value, label) in dataList" :key="label">
      <!-- Custom fields not have translate, so should not translate -->
      <div class="label" v-if="isTranslateAvailable(toSnakeCase(label))">{{ $t(toSnakeCase(label)) }} :</div>
      <div class="label" v-else>{{ label }} :</div>
      <div :class="['value', getDisplayValue(value) === MASKED_ANNOTATION ? 'masked-value' : '']">
        {{ getDisplayValue(value) }}
      </div>
    </div>

    <div v-if="isFilesExist">
      <div v-for="(value, key) in fileList" :key="key" class="file-and-value">
        <div class="text-right">{{ value?.label }} :</div>
        <a>
          <div class="text-left" v-for="(el, idx) in value?.files" :key="`${key}_${idx}`">
            <div @click="onPreview(el.url)">
              <IconListFile />
              <div class="w-full py-2.5">
                <span>{{ el?.name }}</span>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <ImageViewerDialog v-model:imageDialogVisible="imageDialogVisible" :image="imageObj" />

  <el-dialog center width="90%" top="0" :model-value="signatureDialogVisible" :title="eSignCarouselHeaderLabel"
    @closed="signatureDialogVisible = false">
    <div>
      <el-carousel @change="onESignCarouselChange" arrow="always" :autoplay="false" indicator-position="none">
        <el-carousel-item v-for="(eSign, key) in eSignsArr" :key="key">
          <div style="border: 1px solid #c4c4c4; place-self: center">
            <img :src="eSign.eSign" alt="eSign" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button id="AppDialog_closeDialog" type="primary" @click="signatureDialogVisible = false">
          {{ $t('ok') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import ImageViewerDialog from 'smartbarcode-web-core/src/components/ImageViewerDialog.vue'
import IconEdit from '@/components/svg/IconEdit.vue'
import IconImage from '@/components/svg/IconImage.vue'
import IconListFile from 'smartbarcode-web-core/src/components/svg/IconListFile.vue'
import IconMaskedLock from '@/components/svg/IconMaskedLock.vue'
import IconSignature from '@/components/svg/IconSignature.vue'
import { getFilePathFromURL, toSnakeCase } from '@/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { ICustomFieldData, IDynamicCustomFieldData, IFile } from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { MASKED_ANNOTATION } from 'smartbarcode-web-core/src/utils/constants'
import { objectToArray, arrayToObject } from 'smartbarcode-web-core/src/utils/helpers'

export interface IFileInfo {
  files: {
    url: string
    name: string
  }[]
  label: string
}

@Options({
  components: {
    IconEdit,
    IconImage,
    IconMaskedLock,
    IconSignature,
    IconListFile,
    ImageViewerDialog,
  },
  name: 'DetailAddressBox',
  methods: { toSnakeCase },
  emits: ['update:onRefreshReferenceFieldClick'],
})
export default class DetailAddressBox extends Vue {
  @Prop({ type: String }) readonly title!: string
  @Prop({ type: Object }) readonly eSigns!: ICustomFieldData[]
  @Prop({ type: Boolean }) readonly isMaskedImage!: boolean
  @Prop({ type: Boolean }) readonly isMaskedSignature!: boolean
  @Prop({ type: String }) readonly view?: string
  @Prop({ type: Object }) readonly data!: {
    value: string
    label: string
  }

  @Prop({ type: Object }) readonly fileLinks!: Record<string, ICustomFieldData>
  @Prop({ type: Object }) readonly fileImages!: Record<string, IDynamicCustomFieldData>
  @Prop({ type: Boolean }) readonly editable?: boolean
  @Prop({ type: Boolean }) readonly hasReferenceFields?: boolean
  localeList = Object.freeze(['ja', 'en', 'en-us', 'zh-tw', 'zh-cn'])

  imageDialogVisible = false
  signatureDialogVisible = false
  eSignCarouselIndex = 0
  imageCarouselIndex = 0
  image = {}

  get imageObj() {
    if (this.fileImages) {
      // FilterData use later
      const filterData = Object.values(this.fileImages).reduce((acc, curr) => {
        if (curr.files) {
          curr.files = curr.files.map((val) => {
            return { ...val, label: curr.label }
          })
          return { ...acc, [curr.label || '']: curr.files }
        }
        return acc
      }, {})
      return filterData || {}
    }
    return {}
  }

  onImageCarouselChange(idx: number) {
    this.imageCarouselIndex = idx
  }

  get imageCarouselHeaderLabel() {
    return this.imageUrlsArr?.[this.imageCarouselIndex]?.label || ''
  }

  onESignCarouselChange(idx: number) {
    this.eSignCarouselIndex = idx
  }

  get eSignCarouselHeaderLabel() {
    return this.eSignsArr[this.eSignCarouselIndex]?.label || ''
  }

  get eSignsArr() {
    return this.eSigns?.filter((e) => !!e?.eSign) || []
  }

  get imageUrlsArr() {
    if (this.fileImages) {
      let data: IFile[] = []
      // FilterData use later
      Object.values(this.fileImages).map((curr) => {
        if (curr.files) {
          data = [...data, ...curr?.files]
        }
      }, {})
      return data
    }

    return []
  }

  get dataList() {
    if (this.data) {
      const data = objectToArray(cloneDeep(this.data))
      return arrayToObject(
        data.filter((val) => {
          return !isEmpty(val.value)
        })
      )
    }
  }

  get fileList(): { [key: string]: IFileInfo } {
    const fileLinks = this.fileLinks || {}
    const files = Object.keys(fileLinks).reduce((acc, currKey) => {
      if (!isEmpty(fileLinks?.[currKey].files)) {
        return { ...acc, [currKey]: this.fileLinks[currKey] }
      }
      if (fileLinks && !isEmpty(fileLinks?.[currKey]?.reference?.file)) {
        return { ...acc, [currKey]: { ...this.fileLinks[currKey], files: [this.fileLinks[currKey]?.reference?.file] } }
      }
      return acc
    }, {})
    return files
  }

  get isFilesExist() {
    return !isEmpty(this.fileList)
  }

  get MASKED_ANNOTATION() {
    return MASKED_ANNOTATION
  }

  onPreview(url: string) {
    window.open(url, '_blank')
  }

  onDisplayActivateImage() {
    if (this.isMaskedImage) return
    this.imageDialogVisible = true
  }

  onDisplaySignature() {
    if (this.isMaskedSignature) return
    this.signatureDialogVisible = true
  }

  getFileNameFromLink(linkFile: { url: string }) {
    return getFilePathFromURL(linkFile.url || '')
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  isTranslateAvailable(key: string) {
    let isFound = false
    if (!this.localeList) return
    this.localeList.forEach((val) => {
      if (this.$te(key, val)) {
        isFound = true
      }
    })
    return isFound
  }

  getDisplayValue(value: string | number) {
    return value
  }

  onClickEdit() {
    this.$router.push({ name: 'edit', params: { view: this.view } })
  }

  onClickRefreshReferenceField() {
    this.$emit('update:onRefreshReferenceFieldClick')
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.masked-value {
  color: #ff0000;
}

.address-box {
  padding: 14px 0;

  .label-box {
    margin-bottom: 12px;
    display: inline-block;
  }
}

.el-button.edit-button {
  padding: 6px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.tracking-point {
  background: $light-grey;
  padding: 4px 0;
  width: 100%;
  border: 0;
}

.label-and-value>.value {
  word-break: break-word;
}

.label-and-value>.label {
  word-break: break-word;
}

.image-dialog {
  &.el-dialog {
    .el-dialog__body {
      padding-top: 0;
    }
  }
}

.image-dialog:deep() {
  &.el-dialog {
    background-color: #f2f2f2;
    border-radius: 10px;
    max-height: 90%;
    overflow: hidden;
    flex-direction: column;
    display: flex;
    margin: 0;

    &__header {
      font-weight: bold;
      border-bottom: 1px solid #bdbdbd;
      padding: 25px;
    }

    .el-carousel {
      &__item {
        justify-content: center;
        display: flex;
      }
    }

    .el-image {
      overflow: auto;

      &__inner {
        height: auto;
      }
    }
  }
}

.file-and-value {
  display: flex;
  padding: 8px 0px;

  >div {
    flex-basis: 35%;
    text-align: right;
    margin-right: 8px;
    min-width: 35%;
  }
}
</style>

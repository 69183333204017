<template>
  <SvgBase iconName="pairing" width="50" height="30">
    <path d="M9.15991 11.96H41.4699" stroke="white" stroke-width="4" />
    <path
      d="M11.9601 16.6001C9.40007 16.6001 7.32007 14.5201 7.32007 11.9601C7.32007 9.40005 9.40007 7.32007 11.9601 7.32007C14.5201 7.32007 16.6001 9.40005 16.6001 11.9601C16.6001 14.5201 14.5201 16.6001 11.9601 16.6001Z"
      fill="white"
    />
    <path
      d="M11.96 19.92C7.56999 19.92 4 16.35 4 11.96C4 7.56999 7.56999 4 11.96 4C14.25 4 16.29 4.98 17.75 6.53H22.6C20.62 2.66 16.6 0 11.96 0C5.36999 0 0 5.35999 0 11.96C0 18.55 5.35999 23.92 11.96 23.92C16.6 23.92 20.62 21.26 22.6 17.39H17.75C16.29 18.93 14.25 19.92 11.96 19.92Z"
      fill="white"
    />
    <path
      d="M33.1001 11.9601C33.1001 9.40005 35.1801 7.32007 37.7401 7.32007C40.3001 7.32007 42.3801 9.40005 42.3801 11.9601C42.3801 14.5201 40.3001 16.6001 37.7401 16.6001C35.1801 16.6001 33.1001 14.5201 33.1001 11.9601Z"
      fill="white"
    />
    <path
      d="M37.7401 19.92C42.1301 19.92 45.7001 16.35 45.7001 11.96C45.7001 7.56999 42.1301 4 37.7401 4C35.4501 4 33.4101 4.98 31.9501 6.53H27.1001C29.0801 2.66 33.1001 0 37.7401 0C44.3301 0 49.7001 5.35999 49.7001 11.96C49.7001 18.55 44.3401 23.92 37.7401 23.92C33.1001 23.92 29.0801 21.26 27.1001 17.39H31.9501C33.4101 18.93 35.4601 19.92 37.7401 19.92Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconPairing extends Vue {}
</script>

<template>
  <div class="dialog-area" v-loading.fullscreen="loading">
    <el-dialog v-model="_isShow" width="90%" top="0" lock-scroll center show-close destroy-on-close :title="title">
      <div class="instock-location">
        <div class="display-in-center">
          <div style="min-height:170px; max-height: 200px;overflow-x: hidden; overflow-y: auto;" class="my-3">
            <el-radio-group v-model="selectedLocationId" class="ml-4">
              <div v-for="location in locations" :key="location.id" class="my-2 flex items-start">
                <el-radio :label="location.id"> {{ location.name }}</el-radio>
              </div>
            </el-radio-group>
          </div>

          <LocationScanDialog
            v-if="isShowLocationScanDialog"
            v-model:isShow="isShowLocationScanDialog"
            @validate:locationId="validateAndSelect"
          />
        </div>
      </div>
      <template #footer>
        <div class="flex flex-col items-center">
          <el-button @click="onScanLocation" type="primary" class="w-3/4 my-2">
            <div class="action-button">
              <IconScanQR />
              {{ $t('Scan barcode') }}
            </div>
          </el-button>
          <div class="w-3/4 my-2">
            <el-button @click="doOperation" type="primary" :disabled="isDisableOperationBtn" circle class="w-full my-2">
              {{ $t('save') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import LocationScanDialog from '@/components/dialog/LocationScanDIalog.vue'
import IconScanQR from '@/components/svg/IconScanQR.vue'
import { FETCH_BARCODE, FETCH_LOCATIONS, INSTOCK_LOCATION } from '@/store/actions'
import { EStockOperationType } from 'smartbarcode-web-core/src/utils/enums/index'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/helpers'
import { IBarcode, IInventoryLocation, IProject, TError } from 'smartbarcode-web-core/src/utils/types/index'
import isEmpty from 'lodash/isEmpty'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'

@Options({
  components: {
    IconScanQR,
    LocationScanDialog,
  },
  emits: ['update:isShow'],
  name: 'StockOperationsDialog',
})
export default class StockOperationsDialog extends Vue {
  @Prop({ type: String }) readonly operationType!: EStockOperationType
  @PropSync('isShow', { type: Boolean }) _isShow!: boolean
  loading = false

  isShowLocationScanDialog = false

  scannedLocationId = ''
  selectedLocationId = ''

  get isDisableOperationBtn() {
    return isEmpty(this.selectedLocationId)
  }

  get barcode(): IBarcode {
    return this.$store.state.barcode.barcode
  }

  get project(): IProject {
    return this.$store.state.project.details
  }

  get locations(): IInventoryLocation[] {
    return this.$store.state.location.locations
  }

  onScanLocation() {
    this.isShowLocationScanDialog = true
  }

  NONE = 'none'
  async doOperation() {
    await this.processTask(async () => {
      try {
        await this.$store.dispatch(INSTOCK_LOCATION, {
          barcodeIds: [this.barcode.id],
          ...(this.selectedLocationId !== this.NONE && { locationId: this.selectedLocationId }),
        })
        openMessage(this.$t(`location.${this.operationType.toLowerCase()}_success`), 'success')
        await this.$store.dispatch(FETCH_BARCODE, { id: this.barcode.id })
        await this.$store.dispatch(FETCH_LOCATIONS, {
          projectId: this.project.id,
          barcodeId: this.barcode.id,
          trackPointKey: this.barcode.currentTrackPointKey,
          isNew: true,
        })
      } catch (e) {
        errorHandler(e as TError)
      } finally {
        this._isShow = false
      }
    })
  }

  currentBarcodeId?: string = undefined
  currentProject?: string = undefined

  async fetchLocations() {
    try {
      await this.$store.dispatch(FETCH_LOCATIONS, {
        projectId: this.project.id,
        barcodeId: this.barcode.id,
        trackPointKey: this.barcode.currentTrackPointKey,
      })
    } catch (e) {
      errorHandler(e as TError)
    }
  }

  async processTask(doTask: Function) {
    this.loading = true
    await doTask()
    this.loading = false
  }

  validateAndSelect(id: string) {
    const found = this.locations.find((location) => location.id === id)
    if (found) {
      this.selectedLocationId = id
      openMessage(this.$t('location.location_was_chosen', { name: found.name }), 'success')
      this.isShowLocationScanDialog = false
    } else {
      openMessage(this.$t('location.location_not_found'), 'error')
    }
  }

  get title() {
    return this.$t('location.select_location')
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';
.dialog-area:deep() {
  .el-overlay {
    .el-dialog {
      &__body {
        width: 90%;
        padding: 20px 20px 10px 20px !important;
      }
    }
  }
}

.instock-location {
  // border: 1px solid $mid-grey;
  margin: 0px 0px 0px 0px;
  padding: 10px 20px;
  border-radius: 20px;
}
</style>

<template>
  <SvgBase iconName="printer" width="34" height="38">
    <path
      d="M9.18807 5.82764H18.6803C21.6122 5.82764 28.7696 6.32522 29.4968 11.2015C30.3236 16.7513 24.9038 19.3158 13.6356 18.8565C8.85891 18.6574 2.41339 18.8565 2.41339 25.8608C2.41339 29.6194 7.19777 31.8776 12.7783 31.8776H25.5162"
      :stroke="color"
      stroke-width="3.9231"
    />
    <path
      d="M5.82751 9.65501C7.94138 9.65501 9.65501 7.94138 9.65501 5.82751C9.65501 3.71363 7.94138 2 5.82751 2C3.71363 2 2 3.71363 2 5.82751C2 7.94138 3.71363 9.65501 5.82751 9.65501Z"
      :stroke="color"
      stroke-width="3.9685"
    />
    <path
      d="M28.1724 35.7126C30.2863 35.7126 32 33.999 32 31.8851C32 29.7713 30.2863 28.0576 28.1724 28.0576C26.0586 28.0576 24.3449 29.7713 24.3449 31.8851C24.3449 33.999 26.0586 35.7126 28.1724 35.7126Z"
      :stroke="color"
      stroke-width="3.9685"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconTrace extends Vue {}
</script>

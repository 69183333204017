<template>
  <el-dialog :lock-scroll="true" width="90%" top="0" :model-value="_isShow" :close-on-click-modal="false"
    :show-close="false" @closed="_isShow = false" :title="$t('linking.linking_options')">
    <template #footer>
      <span class="dialog-footer">
        <div>
          <el-button type="primary" class="full-w-button" @click="link(ELinkType.LINK_TO)">
            {{ $t('linking.link_to') }}
          </el-button>
        </div>
        <div>
          <el-button type="primary" class="full-w-button" @click="link(ELinkType.BE_LINKED)">
            {{ $t('linking.link_by') }}
          </el-button>
        </div>
        <div>
          <el-button @click="_isShow = false" class="full-w-button">
            {{ $t('cancel') }}
          </el-button>
        </div>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { ELinkType } from '@/utils/enums'
import { Options, Vue } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'

@Options({
  components: {},
  name: 'LinkingOptionsDialog',
})
export default class LinkingOptionsDialog extends Vue {
  @PropSync('isShow', { type: Boolean }) _isShow!: boolean

  ELinkType = ELinkType

  link(linkType: ELinkType) {
    this.$router.push({
      name: `${linkType}-scan`,
      params: {
        project: this.$store.getters.projectParam,
        barcodeId: this.$store.state.barcode.barcode?.id,
      },
    })
  }
}
</script>

<template>
  <div class="view" v-loading.fullscreen="loading">
    <div class="display-in-center" v-if="barcode" :key="barcode.audit.updatedDateTime || barcode.audit.createdDateTime">
      <div class="help-block-container">
        <CompanyName />
        <div v-if="!!userGuideImage" class="help-block-container__child">
          <IconHelp class="cursor-pointer" @click="showUserGuideImage" />
        </div>
      </div>
      <div class="new-code-view display-in-center">
        <div class="qr-container" v-if="dataUrl && isShowQrCodeVisibility">
          <img :src="dataUrl" class="qr-code" id="qr-code" />
          <el-button v-if="isAuth" circle type="primary" class="print-button" @click="print">
            <IconPrinter />
          </el-button>
        </div>
        <div class="info">
          <p>
            <span class="small-label-box">ID</span>
            {{ barcode?.id }}
          </p>
          <p>
            <span class="small-label-box">{{ $t('Creation time') }}</span>
            {{ barcodeCreateTime }}
          </p>
          <p v-if="barcode?.barcodeType" class="barcocde-type">
            <span class="small-label-box">{{ $t('Barcode type') }}</span>
            <BarcodeLabel lite :type="barcode?.barcodeType" />
            <span>{{ barcodeTypeInfo?.name ?? '' }}</span>
          </p>
        </div>

        <!-- if the barcode is not activated  -->
        <router-link id="Detail_OnClickActivateQRcode" v-if="isAuth && !barcode?.isActivated" :to="{
    name: 'activate-type',
    params: {
      project: projectParam,
      barcodeId: barcode.id,
    },
  }">
          <el-button type="primary" class="activate">
            <div class="action-button">
              <IconActivate />
              {{ $t('Activate') }}
            </div>
          </el-button>
        </router-link>

        <div v-else>
          <hr v-if="hasChildren" />
          <div class="barcode-number-block" v-if="isAuth && hasChildren">
            <span>
              {{ $t('Number of child barcode') }}
            </span>
            <span class="label-box"> {{ barcode?.childrenCount }} </span>
          </div>

          <hr />
          <TrackingPointNameBox :title="$t('Tracking Point')" :data="trackingPoints" />

          <TrackedDataFlow :class="`${isDrawing ? 'opacity-0' : ''}`" v-if="!isHideDataFlow" :barcode="barcode"
            ref="trackedDataFlow" :key="drawingKey" />

          <hr />
          <div v-if="maskedFields.length > 0"
            style="margin-left: 50px; margin-right: 50px; color: red; font-family: Noto Sans JP !important"
            :class="`text-center px-4 py-3 bg-white rounded-lg`">
            {{ $t('Masked permission items') }}
          </div>

          <template v-if="hasOrigin || originAvailable">
            <DetailAddressBox v-if="origin" :title="$t('origin')" view="origin" :data="origin || {}"
              :editable="allowToEdit" />
          </template>
          <template v-if="hasDestination || destinationAvailable">
            <DetailAddressBox v-if="destination" :title="$t('destination')" view="destination" :data="destination || {}"
              :editable="allowToEdit" />
          </template>
          <template v-if="isShowPackageInfo || packageInfoAvailable">
            <DetailAddressBox @update:onRefreshReferenceFieldClick="onRefreshReferenceFieldClick"
              :hasReferenceFields="hasReferenceFields" :title="$t('package_info')" view="packageInfo"
              :data="dimension || {}" :editable="allowToEdit" :fileLinks="getFilesArrFromCustomFields(false) || {}"
              :fileImages="getFilesArrFromCustomFields(true) || {}" :isMaskedImage="isMaskedImage"
              :isMaskedSignature="isMaskedEsign" :eSigns="ESignArrayObj || []" />
          </template>

          <hr />
          <DetailPageButtons :barcode="barcode" :currentTrackPointKey="currentTrackPointKey"
            :barcodeTypeInfo="barcodeTypeInfo" :childrenCount="childrenCount" v-model:loading="loading"
            @update:onParentButtonClicked="onParentButtonClicked" />
        </div>
      </div>
    </div>
  </div>

  <el-dialog width="90%" :lock-scroll="true" top="0" :model-value="showDialogAlert" :close-on-click-modal="true"
    :show-close="false" @closed="onDialogClose()">
    <div v-if="showUserGuideImageDialog">
      <el-image :preview-src-list="[userGuideImage]" :src="userGuideImage" />
    </div>
    <div v-else-if="!!errorDialogMessage" class="dialogMessage">
      {{ $t(errorDialogMessage) }}
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="showDialogAlert = false">
          {{ $t('OK') }}
        </el-button>
      </span>
    </template>
  </el-dialog>

  <ParentViewerDialog v-model:parentDialogVisibility="isParentDialogVisible" />
</template>

<script lang="ts">
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import NotFound from '@/components/common/NotFound.vue'
import CompanyName from '@/components/CompanyName.vue'
import TrackedDataFlow from '@/components/detail/TrackedDataFlow.vue'
import DetailAddressBox from '@/components/DetailAddressBox.vue'
import DetailPageButtons from '@/components/DetailPageButtons.vue'
import BarcodeKeyValueInfoMixin from '@/components/mixins/BarcodeKeyValueInfoMixin.vue'
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'
import ParentViewerDialog from '@/components/ParentViewerDialog.vue'
import IconActivate from '@/components/svg/IconActivate.vue'
import IconEdit from '@/components/svg/IconEdit.vue'
import IconHelp from '@/components/svg/IconHelp.vue'
import IconPrinter from '@/components/svg/IconPrinter.vue'
import TrackingPointNameBox from '@/components/TrackingPointNameBox.vue'
import { BARCODE_ACTION_COMPLETE, FETCH_BARCODE, FETCH_LOCATIONS, SET_PAGE_NOT_FOUND } from '@/store/actions'
import { updateReferenceFieldBarcode } from '@/utils/api'
import { DATE_TIME } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { isHideVisibilityConfig, openMessage } from '@/utils/helpers'
import { format } from 'date-fns'
import { jsPDF as JsPDF } from 'jspdf'
import isEmpty from 'lodash/isEmpty'
import QRCode from 'qrcode'
import { ECustomFieldType, EFileKind } from 'smartbarcode-web-core/src/utils/enums/index'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
import {
  IBarcode,
  ICustomFieldData,
  IFieldSettings,
  IFormInputSchema,
  IProject,
  TError,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { NavigationGuardNext, RouteLocation, RouteLocationNormalized } from 'vue-router'

interface ILocation {
  id: string
  name: string
  isActive?: boolean
}

@Options({
  name: 'DetailView',
  components: {
    IconPrinter,
    IconEdit,
    IconHelp,
    IconActivate,
    CompanyName,
    DetailAddressBox,
    BarcodeLabel,
    NotFound,
    TrackingPointNameBox,
    TrackedDataFlow,
    DetailPageButtons,
    ParentViewerDialog,
  },
  beforeRouteUpdate(to: RouteLocation, from: RouteLocation) {
    this.loading = true
    try {
      // Refetch the barcode if different barcodeId when route change
      if (to.params.barcodeId !== from.params.barcodeId) {
        this.$store.dispatch(FETCH_BARCODE, {
          id: to.params.barcodeId,
          path: to.query?.path ?? '',
          trackingNumber: to.query?.trackingNumber ?? '',
          externalId: to.query?.externalId ?? '',
        })
      }
    } catch (error) {
      errorHandler(error as Error)
    } finally {
      this.loading = false
    }
  },
})
export default class DetailView extends mixins(BarcodeTypesMixin, BarcodeKeyValueInfoMixin) {
  barcodeId = '0'
  dataUrl = ''
  loading = true
  showDialogAlert = false
  showUserGuideImageDialog = false
  isDrawing = true
  drawingKey = 0
  isParentDialogVisible = false

  get isShowQrCodeVisibility() {
    const qrCodeVisibility = this.barcodeTypeInfo?.uiConfig?.qrCodeVisibility
    return !isHideVisibilityConfig(qrCodeVisibility, this.isAuth)
  }

  getFilesArrFromCustomFields(isImageFileKind: boolean) {
    const customFields = this.barcode?.activationData?.customFields || {}
    const data = Object.keys(customFields).reduce((acc, currKey) => {
      if (
        (customFields[currKey].fieldType === ECustomFieldType.FILES &&
          (isImageFileKind ? this.isFileKindImage(currKey) : !this.isFileKindImage(currKey))) ||
        customFields[currKey].fieldType === ECustomFieldType.REFERENCE
      ) {
        const fieldLabel = this.barcodeTypeInfo?.activationFields?.customFields?.[currKey]?.label
        return { ...acc, ...{ [currKey]: { ...customFields[currKey], label: fieldLabel } } }
      }
      return acc
    }, {} as Record<string, ICustomFieldData>)
    return data
  }

  onParentButtonClicked() {
    this.isParentDialogVisible = true
  }

  isFileKindImage(key: string) {
    const customFieldsSetting = this.barcodeTypeInfo?.activationFields?.customFields || {}
    if (customFieldsSetting?.[key]?.fieldType === ECustomFieldType.FILES) {
      return [EFileKind.BARCODE_SCAN_FRAME, EFileKind.IMAGE, EFileKind.PHOTO_ONLY].find((imageKey) => {
        return customFieldsSetting?.[key]?.fileKind === imageKey
      })
    }
    return false
  }

  get ESignArrayObj() {
    const activationDataCF = this.barcode?.activationData?.customFields || {}
    const activationSettingCF: Record<string, IFormInputSchema> =
      this.barcodeTypeInfo?.activationFields?.customFields || {}
    const data = Object.keys((activationDataCF || {}) as IFormInputSchema).reduce((acc, currKey) => {
      if (activationDataCF?.[currKey]?.fieldType === ECustomFieldType.ESIGN) {
        const eSign = {
          fieldType: ECustomFieldType.ESIGN,
          label: activationSettingCF?.[currKey]?.label,
          eSign: activationDataCF?.[currKey].eSign,
        }
        acc.push(eSign)
      }
      return acc
    }, [] as ICustomFieldData[])
    return data
  }

  get isAuth(): boolean {
    return this.$store.getters.isAuth
  }

  get isClientUser(): boolean {
    return this.$store.getters.isClientUser
  }

  get project(): IProject {
    return this.$store.state.project.details
  }

  get currentTrackPointKey() {
    return this.barcode?.currentTrackPointKey
  }

  get currentTrackPoint(): string {
    return this.project?.trackPoints?.[this.currentTrackPointKey]?.name || '---'
  }

  get trackingPoints() {
    return {
      start: this.startTrackPoint || '',
      current: this.currentTrackPoint || '',
    }
  }

  get packageInfoAvailable() {
    return (
      this.isTrackingNumberAvailable ||
      this.isImageDataAvailable ||
      this.isExternalIdAvailable ||
      this.isImageDataAvailable ||
      this.isDimensionAvailable ||
      this.isActivationDataCustomFieldsAvailable
    )
  }

  get isActivationDataCustomFieldsAvailable() {
    const isCustomFieldsAvailable = objectToArray<IFieldSettings>(
      this.flattenFields(this.barcodeTypeInfo?.activationFields)
    ).some((val) => val?.value?.isAvailable)
    return isCustomFieldsAvailable || false
  }

  get destinationAvailable() {
    return this.barcodeTypeInfo?.activationFields?.destination?.isAvailable
  }

  get originAvailable() {
    return (
      this.barcodeTypeInfo?.activationFields?.origin?.isAvailable ||
      this.barcodeTypeInfo?.activationFields?.maskedFields?.includes('origin')
    )
  }

  get hasOrigin(): boolean {
    return !isEmpty(this.barcode?.activationData?.origin) && this.barcodeTypeInfo?.activationFields?.origin?.isAvailable
  }

  get hasDestination(): boolean {
    return (
      !isEmpty(this.barcode?.activationData?.destination) &&
      this.barcodeTypeInfo?.activationFields?.destination?.isAvailable
    )
  }

  get isShowPackageInfo() {
    const maskedFields = this.maskedFields
    const packageInfoMaskedFields =
      maskedFields?.filter((val) => {
        if (val !== 'origin' && val !== 'destination') return val
      }) || []
    return (
      !isEmpty(this.barcode?.activationData?.dimension) ||
      !isEmpty(this.barcode?.activationData?.trackingNumber) ||
      !isEmpty(this.dimension) ||
      packageInfoMaskedFields?.length > 0
    )
  }

  get barcode(): IBarcode {
    return this.$store.state.barcode?.barcode
  }

  get barcodeCreateTime() {
    return this.barcode?.audit.createdDateTime
      ? format(new Date(this.barcode.audit.createdDateTime), DATE_TIME.WITH_SECOND)
      : ''
  }

  get actionCompleted() {
    return this.$store.state.barcode.actionCompleted
  }

  get projectParam() {
    return this.$store.getters.projectParam
  }

  get maskedFields() {
    return this.barcode?.activationData?.maskedFields || []
  }

  get getImagesKeyArr() {
    const activationCustomField = this.barcodeTypeInfo?.activationFields?.customFields
    if (!activationCustomField) return
    const data = Object.keys((activationCustomField || {}) as IFormInputSchema).reduce((acc, currKey) => {
      if (
        activationCustomField[currKey]?.fieldType === ECustomFieldType.FILES &&
        activationCustomField[currKey]?.fileKind === 'image'
      ) {
        acc.push(currKey)
      }
      return acc
    }, [] as string[])
    return data || []
  }

  get isMaskedImage() {
    const fileArray = this.getImagesKeyArr || []
    const isMaskedImage = fileArray.some((key) => this?.maskedFields?.includes(`customFields.${key}`))
    return isMaskedImage || false
  }

  get isMaskedEsign() {
    const fileArray = this.getEsignsKeyArr || []
    const isMaskedEsignField = fileArray.some((key) => this?.maskedFields?.includes(`customFields.${key}`))
    return isMaskedEsignField || false
  }

  get getEsignsKeyArr() {
    const activationCustomField = this.barcodeTypeInfo?.activationFields?.customFields
    if (!activationCustomField) return
    const data = Object.keys((activationCustomField || {}) as IFormInputSchema).reduce((acc, currKey) => {
      if (activationCustomField[currKey]?.fieldType === ECustomFieldType.ESIGN) {
        acc.push(currKey)
      }
      return acc
    }, [] as string[])
    return data || []
  }

  get origin() {
    return this.$store.getters.origin
  }

  get destination() {
    return this.$store.getters.destination
  }

  async onRefreshReferenceFieldClick() {
    try {
      this.loading = true
      await updateReferenceFieldBarcode(this.barcode.id)
      await this.$store.dispatch(FETCH_BARCODE, {
        id: this.$route.params.barcodeId,
        trackingNumber: this.trackingNumberFromPath,
        externalId: this.externalIdFromPath,
        path: this.queryPath,
      })
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.loading = false
    }
  }

  showUserGuideImage() {
    this.showUserGuideImageDialog = true
    this.showDialogAlert = true
  }

  onDialogclose() {
    this.showDialogAlert = false
    this.showUserGuideImageDialog = false
  }

  get childCount(): number {
    return this.barcode?.childrenCount || 0
  }

  get childrenCount() {
    return this.barcode?.childrenCount || 0
  }

  get hasChildren(): boolean {
    return this.childCount > 0
  }

  get hasReferenceFields(): boolean {
    const activationCustomField = this.barcode?.activationData?.customFields
    const isActivationCFAvailable = this.isReferenceAvailable(activationCustomField)

    const isTrackCFAvailable = Object.values(this.barcode?.trackingData).some((val) => {
      return this.isReferenceAvailable(val?.customFields as Record<string, ICustomFieldData>)
    })
    return isActivationCFAvailable || isTrackCFAvailable
  }

  isReferenceAvailable(customField?: Record<string, ICustomFieldData>) {
    const customFields = customField || {}
    const activationCustomField = Object.keys(customFields).some(
      (key) => customFields?.[key]?.fieldType === ECustomFieldType.REFERENCE
    )
    return activationCustomField
  }

  get allowToEdit(): boolean {
    return !this.barcode.isDeactivated && isEmpty(this.barcode?.trackingData) && this.isAuth
  }

  get startTrackPoint() {
    // Take startTrackPointKeyOverride of barcode for start if it exist
    const startOverride = this.barcode?.startTrackPointKeyOverride
    const project = this.$store.state.project.details

    return startOverride ? project?.trackPoints[startOverride].name : project?.startTrackPoint?.name
  }

  @Watch('isHideDataFlow')
  @Watch('project')
  reDrawLeaderLine() {
    this.drawingKey++
    try {
      this.loading = true
      this.$nextTick(async () => {
        if (!this.isHideDataFlow && this.$refs.trackedDataFlow) {
          queueMicrotask(() => this.$refs.trackedDataFlow.drawLeaderLine())
          this.isDrawing = false
          this.loading = false
        }
      })
    } catch (e) {
    } finally {
      this.loading = false
    }
  }

  get isHideDataFlow(): boolean {
    const visibility = this.barcodeTypeInfo?.uiConfig?.trackpointFlowchartVisibility
    return isHideVisibilityConfig(visibility, this.isAuth)
  }

  get userGuideImage(): string {
    return this.barcodeTypeInfo?.userGuideImages?.[this.currentTrackPointKey]
  }

  @Watch('$store.state.barcode.barcode', { deep: true })
  onBarcodeChanged() {
    this.generateQR()
    this.reDrawLeaderLine()
    this.loading = false
  }

  generateQR() {
    if (this.barcode && this.barcodeId !== this.barcode.id) {
      this.barcodeId = this.barcode.id
      const appHost = process.env.VUE_APP_BASE_URL
      const url = `${appHost}/${this.projectParam}/qrcode/${this.barcode.id}`
      QRCode.toDataURL(url).then((dataUrl) => (this.dataUrl = dataUrl))
    }
  }

  print() {
    const img = document.querySelector('#qr-code')
    const width = 55
    const height = 40
    const barcodeWidth = 38
    const paddingTop = 1
    const doc = new JsPDF({ orientation: 'landscape', unit: 'mm', format: [height, width] })
    doc.setFontSize(10)
    doc.addImage(img as HTMLCanvasElement, 'JPEG', (width - barcodeWidth) / 2, paddingTop, barcodeWidth, barcodeWidth)
    if (this.barcode?.activationData?.trackingNumber) {
      doc.text(`${this.barcode?.activationData?.trackingNumber}`, width / 2, barcodeWidth + paddingTop, {
        align: 'center',
      })
    }

    doc.save(`sticker-${this.barcode.id}.pdf`)
  }

  get hasCustomHTML(): boolean {
    const hasCustomContentTemplates = !!this.barcodeTypeInfo?.customContentTemplates

    const keys: string[] = this.barcodeTypeInfo?.customContentTemplates?.[0]?.trackPointKeys ?? []

    return hasCustomContentTemplates && keys.includes(this.currentTrackPointKey) && !this.isAuth
  }

  get trackingNumberFromPath() {
    return this.$route.query?.trackingNumber ?? ''
  }

  get externalIdFromPath() {
    return this.$route.query?.externalId ?? ''
  }

  get queryPath() {
    return this.$route.query?.path ?? ''
  }

  beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const restrictBackRoutes = [
      'shipping-packageInfo',
      'shipping-origin',
      'shipping-destination',
      'activate-trackpoint',
      'activate-type',
    ]
    const name = to.name as string

    const isAllowToBack =
      !(this.barcode.isActivated && restrictBackRoutes.includes(name)) &&
      !(this.barcode.isDeactivated && name === 'track')

    next(isAllowToBack)
  }

  // fetch locations for this project and current trackpoint
  async fetchLocations() {
    try {
      await this.$store.dispatch(FETCH_LOCATIONS, {
        projectId: this.project.id,
        barcodeId: this.barcode.id,
        trackPointKey: this.barcode.currentTrackPointKey,
      })
    } catch (e) {
      errorHandler(e as TError)
    }
  }

  async mounted() {
    await this.$store.dispatch(FETCH_BARCODE, {
      id: this.$route.params.barcodeId,
      trackingNumber: this.trackingNumberFromPath,
      externalId: this.externalIdFromPath,
      path: this.queryPath,
    })

    if (this.project && this.barcode) {
      // Reload to match the project code and barcodeId
      this.$router.replace({
        name: 'detail',
        params: { project: this.$store.getters.projectParam, barcodeId: this.barcode?.id },
      })

      if (!isEmpty(this.barcode?.trackingData)) {
        await this.fetchLocations()
      }
    }

    // validate barcodeType and isActivated barcode
    if ((isEmpty(this.barcodeTypeInfo) && this.barcode?.isActivated) || !this.barcode) {
      this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'barcode' })
      this.loading = false
    }

    // forward to custom content if have
    if (this.hasCustomHTML) {
      this.moveToCustomHtmlPage()
      return
    }

    // notify action completed
    if (!this.actionCompleted) return
    this.onActionCompleted(this.actionCompleted)
  }

  moveToCustomHtmlPage() {
    if (!this.hasCustomHTML) return
    this.$router.push({ name: 'custom-html' })
  }

  @Watch('$store.state.barcode.error')
  async onBarcodeErrorUpdate(error: boolean) {
    if (error) {
      this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'barcode' })
      this.loading = false
    } else {
      window.scrollTo(0, 0)
      this.generateQR()
    }
  }

  get isUIConfigAvailable() {
    return !isEmpty(this.barcodeTypeInfo?.uiConfig)
  }

  @Watch('$store.state.barcode.actionCompleted')
  onActionCompleted(action: string) {
    if (!action) return
    openMessage(this.$t(`barcode ${action}`), 'success')
    this.$store.dispatch(BARCODE_ACTION_COMPLETE, '')
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';

.icon {
  max-width: 100%;
}

.new-code-view {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  text-align: center;
  padding-bottom: 8px;

  >div {
    width: 100%;
  }

  .qr-container {
    width: auto;
    text-align: left;
    position: relative;
    margin: 15px auto;
  }

  .info {
    width: auto;
    text-align: left;
  }
}

.qr-code {
  width: 200px;
  height: 200px;
}

.activate {
  margin-top: 24px;
  width: 240px;
}

.barcocde-type {
  display: flex;
  align-items: center;
}

.print-button {
  position: absolute;
  right: -42px;
  bottom: 0;
}
</style>

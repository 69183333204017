
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import IconBarcodeLink from '../svg/IconBarcodeLink.vue'
import { IBarcode } from 'smartbarcode-web-core/src/utils/types/index'

@Options({
  components: {
    IconBarcodeLink,
  },
})
export default class LinkScanButton extends Vue {
  @Prop({ type: Boolean }) readonly isDisable?: boolean = false
  @Prop({ type: Object }) readonly barcode!: IBarcode

  async onClickActionButton(view: string, id?: string) {
    this.$router.push({
      name: view,
      params: {
        project: this.projectParam,
        barcodeId: id || this.barcode?.id,
      },
    })
  }

  get projectParam() {
    return this.$store.getters.projectParam
  }
}

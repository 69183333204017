<template>
  <SvgBase iconName="IconRightArrow" width="13" height="13" fill="none">
    <path
      d="M6.99457 8.35181e-07L5.30103 1.833L8.39984 5.2L-2.30506e-07 5.2L1.31079e-07 7.8L8.39984 7.8L5.30103 11.167L6.99457 13L13 6.5L6.99457 8.35181e-07Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'
@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconRightArrow extends Vue {}
</script>

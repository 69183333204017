
import { IBarcode, IDisplayBarcodes } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, mixins } from 'vue-class-component'
import { Emit, PropSync } from 'vue-property-decorator'
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import BarcodeKeyValueInfoMixin from './mixins/BarcodeKeyValueInfoMixin.vue'
import IconUnpairing from '@/components/svg/IconUnpairing.vue'
import errorHandler from '@/utils/errorHandler'
import { getBarcode, unpairBarcode } from '@/utils/api'
import { addLabelToBarcode, openMessage } from '@/utils/helpers'
import { FETCH_BARCODE } from '@/store/actions'

// Mixins
@Options({
  components: { BarcodeLabel, IconUnpairing },
  emits: ['update:modelValue', 'update:parentDialogVisibility'],
  name: 'ParentDialog',
})
export default class ImageViewDialog extends mixins(BarcodeKeyValueInfoMixin) {
  @PropSync('parentDialogVisibility') isVisible!: boolean
  loading = false
  parent: IDisplayBarcodes[] = []

  @Emit('update:parentDialogVisibility')
  closeDialog() {
    return false
  }

  goToDetail(barcodeId: string) {
    this.closeDialog()
    this.$router.push({
      name: 'detail',
      params: {
        project: this.projectParam,
        barcodeId: barcodeId,
      },
    })
  }

  get projectParam() {
    return this.$store.getters.projectParam
  }

  async onDialogOpen() {
    this.loading = true
    try {
      if (this.parent.length < 1 || this.parent[0].id !== this.barcode.parentId) {
        // Force the list to empty. This should be removed when multiple parent list support
        this.parent.length = 0

        const parent = await getBarcode(this.barcode?.parentId || '')
        this.parent.push(parent)
        addLabelToBarcode(this.parent, this.project)
      }
    } catch (error) {
      errorHandler(error as Error)
    } finally {
      this.loading = false
    }
  }

  get queryPath() {
    return this.$route.query?.path ?? ''
  }

  async unpair() {
    await this.$confirm(this.$t('confirm_unpair'), '', {
      center: true,
      confirmButtonText: this.$t('ok'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(async () => {
        try {
          this.loading = true
          await unpairBarcode({
            parentBarcodeId: this.barcode?.parentId || '',
            isUnpairAll: false,
            unpairChildrenIds: [this.barcode?.id],
          })

          await this.$store.dispatch(FETCH_BARCODE, {
            id: this.$route.params.barcodeId,
            path: this.queryPath,
          })

          openMessage(this.$t('barcode unpaired'), 'success')
        } catch (error) {
          errorHandler(error as Error)
        } finally {
          this.loading = false
          this.closeDialog()
        }
      })
      .catch(() => true)
  }

  get barcode(): IBarcode {
    return this.$store.state.barcode?.barcode
  }

  get dialogLabel() {
    return this.$t('View Parent')
  }
}

<template>
  <SvgBase iconName="printer" width="36" height="36">
    <path d="M15.372 15.8115H10.1791V21.0331H15.372V15.8115Z" :fill="color" />
    <path d="M20.5649 10.5977H15.3719V15.8192H20.5649V10.5977Z" :fill="color" />
    <path
      d="M15.5328 31.2447C6.96984 31.2447 0 24.2364 0 15.6185C0 7.00059 6.96984 0 15.5328 0C24.0957 0 31.0656 7.00829 31.0656 15.6185C31.0656 24.2287 24.1034 31.2447 15.5328 31.2447ZM15.5328 3.08057C8.66252 3.08057 3.06367 8.70261 3.06367 15.6185C3.06367 22.5344 8.66252 28.1564 15.5328 28.1564C22.4107 28.1564 28.0019 22.5344 28.0019 15.6185C28.0019 8.70261 22.4107 3.08057 15.5328 3.08057Z"
      :fill="color"
    />
    <path
      d="M25.5373 23.8227L23.371 26.001L29.8374 32.5031L32.0038 30.3248L25.5373 23.8227Z"
      :fill="color"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconCheckChildren extends Vue {}
</script>

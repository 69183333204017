
import ImageViewerDialog from 'smartbarcode-web-core/src/components/ImageViewerDialog.vue'
import IconEdit from '@/components/svg/IconEdit.vue'
import IconImage from '@/components/svg/IconImage.vue'
import IconListFile from 'smartbarcode-web-core/src/components/svg/IconListFile.vue'
import IconMaskedLock from '@/components/svg/IconMaskedLock.vue'
import IconSignature from '@/components/svg/IconSignature.vue'
import { getFilePathFromURL, toSnakeCase } from '@/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { ICustomFieldData, IDynamicCustomFieldData, IFile } from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { MASKED_ANNOTATION } from 'smartbarcode-web-core/src/utils/constants'
import { objectToArray, arrayToObject } from 'smartbarcode-web-core/src/utils/helpers'

export interface IFileInfo {
  files: {
    url: string
    name: string
  }[]
  label: string
}

@Options({
  components: {
    IconEdit,
    IconImage,
    IconMaskedLock,
    IconSignature,
    IconListFile,
    ImageViewerDialog,
  },
  name: 'DetailAddressBox',
  methods: { toSnakeCase },
  emits: ['update:onRefreshReferenceFieldClick'],
})
export default class DetailAddressBox extends Vue {
  @Prop({ type: String }) readonly title!: string
  @Prop({ type: Object }) readonly eSigns!: ICustomFieldData[]
  @Prop({ type: Boolean }) readonly isMaskedImage!: boolean
  @Prop({ type: Boolean }) readonly isMaskedSignature!: boolean
  @Prop({ type: String }) readonly view?: string
  @Prop({ type: Object }) readonly data!: {
    value: string
    label: string
  }

  @Prop({ type: Object }) readonly fileLinks!: Record<string, ICustomFieldData>
  @Prop({ type: Object }) readonly fileImages!: Record<string, IDynamicCustomFieldData>
  @Prop({ type: Boolean }) readonly editable?: boolean
  @Prop({ type: Boolean }) readonly hasReferenceFields?: boolean
  localeList = Object.freeze(['ja', 'en', 'en-us', 'zh-tw', 'zh-cn'])

  imageDialogVisible = false
  signatureDialogVisible = false
  eSignCarouselIndex = 0
  imageCarouselIndex = 0
  image = {}

  get imageObj() {
    if (this.fileImages) {
      // FilterData use later
      const filterData = Object.values(this.fileImages).reduce((acc, curr) => {
        if (curr.files) {
          curr.files = curr.files.map((val) => {
            return { ...val, label: curr.label }
          })
          return { ...acc, [curr.label || '']: curr.files }
        }
        return acc
      }, {})
      return filterData || {}
    }
    return {}
  }

  onImageCarouselChange(idx: number) {
    this.imageCarouselIndex = idx
  }

  get imageCarouselHeaderLabel() {
    return this.imageUrlsArr?.[this.imageCarouselIndex]?.label || ''
  }

  onESignCarouselChange(idx: number) {
    this.eSignCarouselIndex = idx
  }

  get eSignCarouselHeaderLabel() {
    return this.eSignsArr[this.eSignCarouselIndex]?.label || ''
  }

  get eSignsArr() {
    return this.eSigns?.filter((e) => !!e?.eSign) || []
  }

  get imageUrlsArr() {
    if (this.fileImages) {
      let data: IFile[] = []
      // FilterData use later
      Object.values(this.fileImages).map((curr) => {
        if (curr.files) {
          data = [...data, ...curr?.files]
        }
      }, {})
      return data
    }

    return []
  }

  get dataList() {
    if (this.data) {
      const data = objectToArray(cloneDeep(this.data))
      return arrayToObject(
        data.filter((val) => {
          return !isEmpty(val.value)
        })
      )
    }
  }

  get fileList(): { [key: string]: IFileInfo } {
    const fileLinks = this.fileLinks || {}
    const files = Object.keys(fileLinks).reduce((acc, currKey) => {
      if (!isEmpty(fileLinks?.[currKey].files)) {
        return { ...acc, [currKey]: this.fileLinks[currKey] }
      }
      if (fileLinks && !isEmpty(fileLinks?.[currKey]?.reference?.file)) {
        return { ...acc, [currKey]: { ...this.fileLinks[currKey], files: [this.fileLinks[currKey]?.reference?.file] } }
      }
      return acc
    }, {})
    return files
  }

  get isFilesExist() {
    return !isEmpty(this.fileList)
  }

  get MASKED_ANNOTATION() {
    return MASKED_ANNOTATION
  }

  onPreview(url: string) {
    window.open(url, '_blank')
  }

  onDisplayActivateImage() {
    if (this.isMaskedImage) return
    this.imageDialogVisible = true
  }

  onDisplaySignature() {
    if (this.isMaskedSignature) return
    this.signatureDialogVisible = true
  }

  getFileNameFromLink(linkFile: { url: string }) {
    return getFilePathFromURL(linkFile.url || '')
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  isTranslateAvailable(key: string) {
    let isFound = false
    if (!this.localeList) return
    this.localeList.forEach((val) => {
      if (this.$te(key, val)) {
        isFound = true
      }
    })
    return isFound
  }

  getDisplayValue(value: string | number) {
    return value
  }

  onClickEdit() {
    this.$router.push({ name: 'edit', params: { view: this.view } })
  }

  onClickRefreshReferenceField() {
    this.$emit('update:onRefreshReferenceFieldClick')
  }
}

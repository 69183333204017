<template>
  <SvgBase iconName="help" width="36" height="36">
    <path
      d="M34.5369 24.8622C38.3318 15.7006 33.9813 5.19724 24.8197 1.4023C15.6581 -2.39263 5.15479 1.9579 1.35986 11.1195C-2.43507 20.2811 1.91546 30.7844 11.077 34.5793C20.2386 38.3743 30.742 34.0237 34.5369 24.8622Z"
      fill="#2E7CF6"
    />
    <path
      d="M18.0451 30.587C16.9624 30.587 16.1503 29.7749 16.1503 28.6922C16.1503 27.6095 16.9624 26.7975 18.0451 26.7975C19.1278 26.7975 19.9398 27.6095 19.9398 28.6922C20.03 29.7749 19.1278 30.587 18.0451 30.587ZM19.7593 19.3088V22.3764C19.7593 23.3689 18.9473 24.1809 17.9548 24.1809C16.9623 24.1809 16.1503 23.3689 16.1503 22.3764V17.6847C16.1503 16.6922 16.9623 15.8802 17.9548 15.8802C20.3007 15.8802 22.1052 14.3463 22.1052 12.2712C22.1052 10.196 20.3909 8.66214 17.9548 8.66214C15.609 8.66214 13.8045 10.196 13.8045 12.2712C13.8045 13.2636 12.9924 14.0757 11.9999 14.0757C11.0075 14.0757 10.1954 13.2636 10.1954 12.2712C10.1954 8.12079 13.5338 4.96289 17.9548 4.96289C22.3759 4.96289 25.7142 8.12079 25.7142 12.2712C25.7142 15.6997 23.2781 18.5869 19.7593 19.3088Z"
      fill="white"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconHelp extends Vue {}
</script>
